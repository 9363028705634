



































































































import { GetUser, UpdateUser } from '@/http/myApi';
import { BussinessType } from '@/models/BusinessTypeEnum';
import { nipRules, phoneRules } from '@/modules/auth/forms/authRules';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { mapActions, mapMutations, mapState } from 'vuex';

export type Select = {
  text?: string;
  value?: BussinessType;
};

export default Vue.extend({
  data: () => ({
    loading: false,
    snackbar: false,
    valid: false,

    firstname: '',
    lastname: '',
    emailField: '',
    phone: '',
    address1: '',
    address2: '',
    nip: '',
    companyName: '',

    businessTypeTemp: null as Select | null,
  }),
  created() {
    if (!this.user) {
      this.$http.user
        .userRead('current')
        .then((resp) => {
          this.assignUser(resp.data);
        })
        .then(() => this.assignUserToForm());
    } else this.assignUserToForm();
    this.setUserForm({ businessType: (this.user as UpdateUser)?.business_type });
  },
  destroyed() {
    this.setUserForm(null);
  },
  computed: {
    ...mapState('user', ['user', 'email', 'userForm']),
    businessType: {
      get: function(): Select {
        return {
          text:
            this.$t('Account.' + BussinessType[this.userForm?.businessType as number]).toString() ||
            '',
          value: this.userForm?.businessType as BussinessType,
        };
      },
      set: function(newValue) {
        this.setUserForm({ ...this.userForm, businessType: newValue });
      },
    },
    businessTypes(): Select[] {
      return Object.keys(BussinessType)
        .filter((k) => !isNaN(Number(k)))
        .map((k) => {
          return {
            text: this.$t('Account.' + BussinessType[+k]).toString(),
            value: +k as BussinessType,
          };
        });
    },
    phoneRules(): TranslateResult {
      return phoneRules(this.$i18n);
    },
    nipRules(): TranslateResult {
      return nipRules(this.$i18n);
    },
  },
  methods: {
    ...mapMutations('user', ['setUserForm']),
    ...mapActions('user', ['assignUser']),
    assignUserToForm() {
      const user = this.user as GetUser;
      this.firstname = user.first_name ?? '';
      this.lastname = user.last_name ?? '';
      this.emailField = user.email;
      this.companyName = user.company_name ?? '';
      this.address1 = user.address_line1 ?? '';
      this.address2 = user.address_line2 ?? '';
      this.nip = user.nip ?? '';
      this.phone = user.phone ?? '';
    },
    changeAccount() {
      const apiUser = this.user as GetUser;

      this.validate();

      if (this.valid) {
        this.loading = true;
        this.$http.user
          .userUpdate('current', {
            first_name: this.firstname,
            last_name: this.lastname,
            company_name: this.companyName,
            address_line1: this.address1,
            address_line2: this.address2,
            nip: this.nip,
            phone: this.phone,
            business_type: this.userForm?.businessType,
            lang: apiUser.lang,
          })
          .then((resp) => {
            this.snackbar = true;
            this.assignUser(resp.data);
          })
          .catch(() => {
            alert(this.$t('Something went wrong'));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    validate() {
      (this.$refs.form as any).validate();
    },
  },
});
