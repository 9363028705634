import VueI18n from 'vue-i18n';

export function phoneRules(i18n: VueI18n): any {
  return [(v: any) => v.length <= 12 || i18n.t('Account.Phone must be less than 12 numbers')];
}

export function nipRules(i18n: VueI18n): any {
  return [
    (v: any) =>
      v.length <= 20 || v.length === 0 || i18n.t('Account.NIP must be less than 20 characters'),
  ];
}

export function emailRules(emailExist: boolean, i18n: VueI18n): any {
  return [
    (v: any) => !!v || i18n.t('Account.E-mail is required'),
    (v: any) => /.+@.+/.test(v) || i18n.t('Account.E-mail must be valid'),
    () => !emailExist || i18n.t('Account.User with this Email address already exists'),
  ];
}

export function passwordRules(
  password: string,
  i18n: VueI18n,
): { required: any; min: any; passwordMatch: any } {
  return {
    required: (value: any) => !!value || i18n.t('Account.Password is required'),
    min: (v: any) => (v && v.length >= 8) || i18n.t('Account.Min 8 characters'),
    passwordMatch: (v: string) =>
      v === password || i18n.t(`Account.Passwords you entered don't match`),
  };
}

export function policyRules(i18n: VueI18n): any {
  return [(v: any) => !!v || i18n.t('Account.Policy is required')];
}
