






























































































import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  data() {
    return {
      valid: false,
      show0: false,
      show1: false,
      show2: false,
      successSnackbar: false,
      failSnackbar: false,
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
    };
  },
  computed: {
    ...mapState('user', ['email']),
    passwordRules(): any {
      return {
        required: (value: any) => !!value || this.$t('Account.Password is required'),
        min: (v: any) => (v && v.length >= 8) || this.$t('Account.Min 8 characters'),
        passwordMatch: (v: string) =>
          v === this.newPassword || this.$t(`Account.Passwords you entered don't match`),
      };
    },
  },
  methods: {
    changePassword() {
      (this.$refs.form as any).validate();

      if (this.valid) {
        this.$http.user
          .userUserPassword('current', {
            old_password: this.oldPassword,
            new_password: this.newPassword,
          })
          .then(() => {
            this.successSnackbar = true;
          })
          .catch(() => {
            this.failSnackbar = true;
          })
          .finally(() => {
            this.newPassword = '';
            this.repeatPassword = '';
            (this.$refs.form as any).reset();
          });
      }
    },
  },
});
