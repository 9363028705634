






















































































import Vue from 'vue';
import AccountChangeTab from './AccountChangeTab.vue';
import PasswordChangeTab from './PasswordChangeTab.vue';
import SettingsChangeTab from './SettingsChangeTab.vue';

export default Vue.extend({
  components: {
    PasswordChangeTab,
    AccountChangeTab,
    SettingsChangeTab,
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  data() {
    return {
      mobileDrawer: false,
      items: [
        {
          icon: 'mdi-account',
          text: this.$t('Account.Account'),
        },
        {
          icon: 'mdi-lock',
          text: this.$t('Account.Password'),
        },
        {
          icon: 'mdi-cog',
          text: this.$t('Account.Settings.Settings'),
        },
      ],
      selectedItem: 0,
    };
  },
});
