


















































import Vue from 'vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import ChangeLocale from '../../components/ChangeLocale.vue';

export default Vue.extend({
  components: { ChangeLocale },
  data() {
    return {
      metricUnits: true,
      loading: false,
      currentLanguage: this.$i18n.locale,
    };
  },
  computed: {
    ...mapState('user', ['user', 'settings']),
  },
  created() {
    this.metricUnits = this.settings.metricUnits;
  },
  methods: {
    ...mapMutations('user', ['setSettings']),
    ...mapActions(['showSuccessSnackbar', 'showFailedSnackbar']),
    changeMetricSystem(value: boolean) {
      if (value !== this.metricUnits) {
        this.metricUnits = value;

        this.$http.user
          .userMetricUnitsCreate('current', { metric_units: this.metricUnits })
          .then(() => {
            this.showSuccessSnackbar(this.$t('Snackbar.Success'));
          })
          .catch(() => {
            this.metricUnits = !value;
            this.showSuccessSnackbar(this.$t('Snackbar.Something went wrong'));
          })
          .finally(() => {
            this.setSettings({ metricUnits: this.metricUnits });
          });
      }
    },
  },
});
