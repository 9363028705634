import { render, staticRenderFns } from "./SettingsChangeTab.vue?vue&type=template&id=c9660812&scoped=true&"
import script from "./SettingsChangeTab.vue?vue&type=script&lang=ts&"
export * from "./SettingsChangeTab.vue?vue&type=script&lang=ts&"
import style0 from "./SettingsChangeTab.vue?vue&type=style&index=0&id=c9660812&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9660812",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VBtnToggle,VContainer,VFlex,VLayout,VSelect})
