
















import Vue from 'vue';
import AccountTabs from './containers/AccountTabs.vue';

export default Vue.extend({
  components: {
    AccountTabs,
  },
  methods: {},
});
